/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type { PromiseOrValue } from "../../../../../../../common";
import type {
  Migrations,
  MigrationsInterface,
} from "../../../../../../../contracts/submodules/moar-locker/submodules/moar/contracts/Migrations";

const _abi = [
  {
    inputs: [],
    name: "last_completed_migration",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "completed",
        type: "uint256",
      },
    ],
    name: "setCompleted",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

const _bytecode =
  "0x6080604052600080546001600160a01b0319163317905534801561002257600080fd5b5061021e806100326000396000f3fe608060405234801561001057600080fd5b50600436106100415760003560e01c8063445df0ac146100465780638da5cb5b14610065578063fdacd57614610092575b600080fd5b61004f60015481565b60405161005c919061010e565b60405180910390f35b6000546100859073ffffffffffffffffffffffffffffffffffffffff1681565b60405161005c9190610149565b6100a56100a036600461015e565b6100a7565b005b60005473ffffffffffffffffffffffffffffffffffffffff163314610101576040517f08c379a00000000000000000000000000000000000000000000000000000000081526004016100f890610187565b60405180910390fd5b600155565b805b82525050565b6020810161011c8284610106565b92915050565b600073ffffffffffffffffffffffffffffffffffffffff821661011c565b61010881610122565b6020810161011c8284610140565b803561011c565b60006020828403121561017357610173600080fd5b600061017f8484610157565b949350505050565b6020808252810161011c81603381527f546869732066756e6374696f6e206973207265737472696374656420746f207460208201527f686520636f6e74726163742773206f776e65720000000000000000000000000060408201526060019056fea26469706673582212200b8e667a5e388e0865505039018dedbf4b26b6290d22a6c6934c3f255cfaa0b664736f6c634300080f0033";

type MigrationsConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: MigrationsConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class Migrations__factory extends ContractFactory {
  constructor(...args: MigrationsConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): Promise<Migrations> {
    return super.deploy(overrides || {}) as Promise<Migrations>;
  }
  override getDeployTransaction(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  override attach(address: string): Migrations {
    return super.attach(address) as Migrations;
  }
  override connect(signer: Signer): Migrations__factory {
    return super.connect(signer) as Migrations__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): MigrationsInterface {
    return new utils.Interface(_abi) as MigrationsInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): Migrations {
    return new Contract(address, _abi, signerOrProvider) as Migrations;
  }
}
