import { gql } from "@apollo/client";

export const UserFields = gql`
  fragment UserFields on User {
    id
    email
    username
    kycVerified
    roles
    enabled
    emailVerified
    createdAt
    updatedAt

    birthYear
    birthMonth
    firstName
    lastName
    country
    region
    discordId
    discordUserName
    wallets {
      address
      name
      lastVerifyAt
      updatedAt
      createdAt
    }
  }
`;
