import { gql } from "@apollo/client";
import { ChecklistFields } from "./fragment";

export const GET_ALL_CHECKLISTS = gql`
  ${ChecklistFields}
  query GetAllChecklists(
    $offset: Int!
    $limit: Int!
    $where: LoyaltyChecklistFilterArgs
  ) {
    findAllLoyaltyChecklists(offset: $offset, limit: $limit, where: $where) {
      nodes {
        ...ChecklistFields
      }
      totalCount
    }
  }
`;

export const GET_CHECKLIST = gql`
  ${ChecklistFields}
  query GetChecklist($id: String!) {
    findLoyaltyChecklist(id: $id) {
      ...ChecklistFields
    }
  }
`;
