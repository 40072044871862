import { gql } from "@apollo/client";

export const ChecklistFields = gql`
  fragment ChecklistFields on LoyaltyChecklist {
    id
    contract
    isActive
    startTime
    createdAt
    updatedAt
    points
    period
    type
  }
`;
