import { LoadingButton } from "@mui/lab";
import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import { useFormik } from "formik";
import { LoyaltyChecklist } from "../../../generated";

export const ChecklistForm = ({
  initialValues,
  onSubmit,
  loading,
  type,
}: {
  initialValues: Partial<LoyaltyChecklist>;
  onSubmit: (values: LoyaltyChecklist) => void;
  type: "create" | "update";
  loading: boolean;
}) => {
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit();
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Contract"
            name="contract"
            onChange={formik.handleChange}
            value={formik.values.contract}
            error={formik.touched.contract && Boolean(formik.errors.contract)}
            helperText={formik.touched.contract && formik.errors.contract}
          ></TextField>
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            value={formik.values.isActive}
            label="Is Active"
            onChange={formik.handleChange}
            name="isActive"
            control={<Checkbox checked={formik.values.isActive} />}
          ></FormControlLabel>
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Period"
            type={"number"}
            name="period"
            onChange={formik.handleChange}
            value={formik.values.period}
            error={formik.touched.period && Boolean(formik.errors.period)}
            helperText={formik.touched.period && formik.errors.period}
          ></TextField>
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Points"
            type={"number"}
            name="points"
            onChange={formik.handleChange}
            value={formik.values.points}
            error={formik.touched.points && Boolean(formik.errors.points)}
            helperText={formik.touched.points && formik.errors.points}
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <DateTimePicker
            label={"startTime"}
            value={formik.values.startTime}
            onChange={(date: Dayjs) => {
              formik.setFieldValue("startTime", date.toISOString());
            }}
            renderInput={(params) => (
              <TextField name={"startTime"} fullWidth {...params} />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <LoadingButton loading={loading} variant={"contained"} type="submit">
            Submit
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};
