import { Web3Provider } from "@ethersproject/providers";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useWeb3React } from "@web3-react/core";
import { Field, FieldArray, useFormik } from "formik";
import { useMemo, useState } from "react";
import { useAllOwnedTokens } from "../../../common/hooks/useTokens";
import { User } from "../../../generated";
import { countryList } from "../../../utils/countryList";
import { NFT } from "./NFT";
import { Wallets } from "./Wallets";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const UserForm = ({
  initialValues,
  onSubmit,
  loading,
  type,
}: {
  initialValues: Partial<User & { password: string }>;
  onSubmit: (values: User & { password: string }) => void;
  type: "create" | "update";
  loading: boolean;
}) => {
  const web3 = useWeb3React<Web3Provider>();
  const accounts: string[] = useMemo(() => {
    return initialValues?.wallets
      ? initialValues.wallets.map((wallet) => wallet.address)
      : [];
  }, []);
  const {
    tokens,
    totalTokens,
    loading: tokensLoading,
  } = useAllOwnedTokens(web3.library, accounts);

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
  });

  const years = useMemo(() => {
    const tempYears = [];
    const currentYear = new Date().getFullYear();

    for (let i = currentYear; i >= 1930; i--) {
      tempYears.push(i.toString());
    }

    return tempYears;
  }, []);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit();
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            disabled={type === "update"}
            label="Email"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Username"
            name="username"
            value={formik.values.username}
            onChange={formik.handleChange}
            error={formik.touched.username && Boolean(formik.errors.username)}
            helperText={formik.touched.username && formik.errors.username}
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Password"
            name="password"
            type={"password"}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          ></TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="First Name"
            name="firstName"
            onChange={formik.handleChange}
            value={formik.values.firstName}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          ></TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Last Name"
            name="lastName"
            onChange={formik.handleChange}
            value={formik.values.lastName}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            label="Country / Region"
            name="country"
            value={formik.values.country}
            onChange={formik.handleChange}
            SelectProps={{
              MenuProps: {
                sx: { maxHeight: "50%" },
              },
            }}
            error={formik.touched.country && Boolean(formik.errors.country)}
            helperText={formik.touched.country && formik.errors.country}
          >
            {countryList.map((country) => (
              <MenuItem value={country.name}>{country.name}</MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            select
            name="birthMonth"
            value={formik.values.birthMonth}
            label="Birth Month"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            fullWidth
            SelectProps={{
              MenuProps: {
                sx: { maxHeight: "50%" },
              },
            }}
            error={
              formik.touched.birthMonth && Boolean(formik.errors.birthMonth)
            }
            helperText={formik.touched.birthMonth && formik.errors.birthMonth}
          >
            {months.map((month) => (
              <MenuItem value={month}>{month.substring(0, 3)}</MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            select
            name="birthYear"
            value={formik.values.birthYear}
            label="Birth Year"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            fullWidth
            SelectProps={{
              MenuProps: {
                sx: { maxHeight: "50%" },
              },
            }}
            error={formik.touched.birthYear && Boolean(formik.errors.birthYear)}
            helperText={formik.touched.birthYear && formik.errors.birthYear}
          >
            {years.map((year) => (
              <MenuItem value={year}>{year}</MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Discord User Name"
            name="discordUserName"
            value={formik.values.discordUserName}
            onChange={formik.handleChange}
            error={
              formik.touched.discordUserName &&
              Boolean(formik.errors.discordUserName)
            }
            helperText={
              formik.touched.discordUserName && formik.errors.discordUserName
            }
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="roles">Roles</InputLabel>
            <Select
              labelId="roles"
              multiple
              value={formik.values.roles}
              name="roles"
              onChange={formik.handleChange}
              input={<OutlinedInput label="Roles"></OutlinedInput>}
              renderValue={(selected) => {
                return selected.join(", ");
              }}
            >
              <MenuItem key="admin" value="admin">
                <Checkbox checked={formik.values.roles.indexOf("admin") > -1} />
                <ListItemText primary={"admin"} />
              </MenuItem>
              <MenuItem key="user" value="user">
                <Checkbox checked={formik.values.roles.indexOf("user") > -1} />
                <ListItemText primary={"user"} />
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            value={formik.values.enabled}
            label="Kyc Status"
            onChange={formik.handleChange}
            name="kycVerified"
            control={<Checkbox checked={formik.values.kycVerified} />}
          ></FormControlLabel>
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            value={formik.values.enabled}
            label="Enabled"
            onChange={formik.handleChange}
            name="enabled"
            control={<Checkbox checked={formik.values.enabled} />}
          ></FormControlLabel>
        </Grid>

        <Grid item xs={12}>
          <Wallets wallets={initialValues.wallets} loading={loading} />
        </Grid>

        <Grid item xs={12}>
          <Typography fontSize="1.5rem">NFTs</Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            {tokensLoading ? (
              <Grid item xs={12}>
                <CircularProgress />
              </Grid>
            ) : totalTokens <= 0 ? (
              <Grid item xs={12}>
                <Typography>User do not have any NFT yet.</Typography>
              </Grid>
            ) : (
              tokens.map((tokenDetails) => {
                return tokenDetails.tokensWithMetadata.map(
                  (tokenWithMetadata) => {
                    return (
                      <Grid item xs={6} md={4} pr={2}>
                        <NFT
                          tokenWithMetadata={tokenWithMetadata}
                          projectName={tokenDetails.contractType}
                        />
                      </Grid>
                    );
                  }
                );
              })
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <LoadingButton loading={loading} variant={"contained"} type="submit">
            Submit
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};
