import { Web3Provider } from "@ethersproject/providers";
import { useMemo, useEffect, useState } from "react";
import { FwenStaker__factory } from "../contracts/fwen-staker/typechain-types";
import { MoarLocker__factory } from "../contracts/fwen-staker/typechain-types/factories/contracts/submodules/moar-locker/contracts/moar";
import { Hanai, Hanai__factory } from "../contracts/hanai-p2/typechain-types";
import {
  ERC721,
  ERC721__factory,
  Moar,
  Moar__factory,
} from "../contracts/moar/typechain-types";
import {
  PeopleInThePlaceTheyLove,
  PeopleInThePlaceTheyLove__factory,
} from "../contracts/pptl/ethers-contracts";

export const ContractType = {
  MOAR: "MOAR",
  PPTL: "PPTL",
  HANAI_P2: "HANAI_P2",
  FWEN_STAKER: "FWEN_STAKER",
};

export const useTokenContract = (library: Web3Provider, address: string) => {
  const tokenContract = useMemo(() => {
    if (!library?.getSigner() || !address) {
      return;
    }
    console.log("Token Contract", address);

    return Moar__factory.connect(address, library.getSigner());
  }, [library, address]);
  return tokenContract;
};

export const useAdminDelegate = (library: Web3Provider, address: string) => {
  const conctract = useMemo(() => {
    if (!library?.getSigner() || !address) {
      return;
    }
    console.log("Token Contract", address);

    return MoarLocker__factory.connect(address, library.getSigner());
  }, [library, address]);
  return conctract;
};

export const useStakingContract = (library: Web3Provider, address: string) => {
  const stakingContract = useMemo(() => {
    if (!library?.getSigner() || !address) {
      return;
    }
    console.log("Staking Contract", address);
    return FwenStaker__factory.connect(address, library.getSigner());
  }, [library, address]);

  return stakingContract;
};

export const useContract = <
  T extends Moar | PeopleInThePlaceTheyLove | Hanai | ERC721
>(
  tokenContractAddress: string,
  library: Web3Provider,
  projectContract: string
) => {
  const [tokenContract, setTokenContract] = useState<T>(null);

  const [symbol, setSymbol] = useState("");

  useEffect(() => {
    (async () => {
      if (tokenContractAddress && library) {
        if (projectContract === ContractType.PPTL) {
          const contract = PeopleInThePlaceTheyLove__factory.connect(
            tokenContractAddress,
            library.getSigner()
          );
          setTokenContract(contract as any);
        } else if (projectContract === ContractType.HANAI_P2) {
          const contract = Hanai__factory.connect(
            tokenContractAddress,
            library.getSigner()
          );
          setTokenContract(contract as any);
        } else if (projectContract === ContractType.MOAR) {
          const contract = Moar__factory.connect(
            tokenContractAddress,
            library.getSigner()
          );
          setTokenContract(contract as any);
        } else if (projectContract === ContractType.FWEN_STAKER) {
          const contract = FwenStaker__factory.connect(
            tokenContractAddress,
            library.getSigner()
          );
          setTokenContract(contract as any);
        } else {
          const contract = ERC721__factory.connect(
            tokenContractAddress,
            library.getSigner()
          );
          setTokenContract(contract as any);
        }
      }
    })();
  }, [tokenContractAddress, library, projectContract]);

  return tokenContract;
};
