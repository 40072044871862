import { useMutation, useQuery } from "@apollo/client";
import { Card, CardHeader, CardContent, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { buildErrorMessage } from "../../common/graphql/errors";
import {
  GetChecklistQuery,
  GetChecklistQueryVariables,
  UpdateChecklistMutation,
  UpdateChecklistMutationVariables,
} from "../../generated";
import { ChecklistForm } from "./components/ChecklistForm";
import { UPDATE_CHECKLIST } from "./graphql/mutation";
import { GET_CHECKLIST } from "./graphql/query";

export const ChecklistEditPage = () => {
  const params = useParams();
  const { data: checklist, loading: checklistLoading } = useQuery<
    GetChecklistQuery,
    GetChecklistQueryVariables
  >(GET_CHECKLIST, {
    variables: { id: params.id },
  });
  const [updateChecklost, { data, error, loading }] = useMutation<
    UpdateChecklistMutation,
    UpdateChecklistMutationVariables
  >(UPDATE_CHECKLIST);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (data?.updateLoyaltyChecklist.id) {
      enqueueSnackbar("Checklist updated", { variant: "success" });
    }
  }, [data]);

  if (error) {
    enqueueSnackbar(buildErrorMessage(error), { variant: "error" });
  }

  return (
    <Card
      sx={{
        display: "flex",
        height: "100%",
        flexDirection: "column",
        overflow: "scroll",
      }}
    >
      <CardHeader title="Edit Checklist"></CardHeader>
      <CardContent sx={{ flex: 1 }}>
        {checklistLoading && !checklist?.findLoyaltyChecklist ? (
          <CircularProgress />
        ) : (
          <ChecklistForm
            type="update"
            initialValues={{ ...checklist?.findLoyaltyChecklist }}
            loading={loading}
            onSubmit={(values) => {
              updateChecklost({
                variables: {
                  updates: {
                    isActive: values.isActive,
                    contract: values.contract,
                    points: values.points,
                    period: values.period,
                    startTime: values.startTime,
                  },
                  id: params.id,
                },
              });
            }}
          ></ChecklistForm>
        )}
      </CardContent>
    </Card>
  );
};
