export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
  _Any: any;
  _FieldSet: any;
  link__Import: any;
};

export type Audit = {
  __typename?: 'Audit';
  action: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  data?: Maybe<Scalars['JSON']>;
  id: Scalars['String'];
  ip?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type AuditFilterArgs = {
  _and?: InputMaybe<Array<AuditFilterArgs>>;
  _or?: InputMaybe<Array<AuditFilterArgs>>;
  action?: InputMaybe<StringExpression>;
  user?: InputMaybe<UserFilterArgs>;
};

export type AuditPaginated = {
  __typename?: 'AuditPaginated';
  nodes: Array<Maybe<Audit>>;
  totalCount: Scalars['Int'];
};

export type BoolExpression = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _ne?: InputMaybe<Scalars['Boolean']>;
};

export type Client = {
  __typename?: 'Client';
  clientId: Scalars['String'];
  clientSecret?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  grantTypes: Array<GrantType>;
  id: Scalars['String'];
  name: Scalars['String'];
  redirectUris: Array<Scalars['String']>;
  responseTypes: Array<ResponseType>;
  scopes: Array<Scalars['String']>;
  tokenEndpointAuthMethod: ClientAuthMethod;
  updatedAt: Scalars['DateTime'];
};

export enum ClientAuthMethod {
  ClientSecretBasic = 'CLIENT_SECRET_BASIC',
  ClientSecretPost = 'CLIENT_SECRET_POST',
  None = 'NONE'
}

export type CreateClientInput = {
  clientId: Scalars['String'];
  clientSecret?: InputMaybe<Scalars['String']>;
  grantTypes?: InputMaybe<Array<GrantType>>;
  name: Scalars['String'];
  redirectUris?: InputMaybe<Array<Scalars['String']>>;
  responseTypes?: InputMaybe<Array<ResponseType>>;
  scopes?: InputMaybe<Array<Scalars['String']>>;
  tokenEndpointAuthMethod: ClientAuthMethod;
};

export type CreateLoyaltyChecklistInput = {
  contract?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  period?: InputMaybe<Scalars['Float']>;
  points?: InputMaybe<Scalars['Float']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<Scalars['String']>;
};

export type CreateRoleInput = {
  name: Scalars['String'];
};

export type CreateUserInput = {
  discordAvator?: InputMaybe<Scalars['String']>;
  discordId?: InputMaybe<Scalars['String']>;
  discordUserName?: InputMaybe<Scalars['String']>;
  dob?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['String']>;
  kycVerified?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  roles: Array<Scalars['String']>;
  shopifyAccount?: InputMaybe<Scalars['String']>;
  shopifyVerified?: InputMaybe<Scalars['Boolean']>;
  username?: InputMaybe<Scalars['String']>;
};

export enum GrantType {
  AuthorizationCode = 'AUTHORIZATION_CODE',
  ClientCredentials = 'CLIENT_CREDENTIALS',
  Implicit = 'IMPLICIT',
  RefreshToken = 'REFRESH_TOKEN'
}

export type LoyaltyChecklist = {
  __typename?: 'LoyaltyChecklist';
  contract: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  period: Scalars['Float'];
  points: Scalars['Float'];
  startTime: Scalars['DateTime'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type LoyaltyChecklistFilterArgs = {
  _and?: InputMaybe<Array<LoyaltyChecklistFilterArgs>>;
  _or?: InputMaybe<Array<LoyaltyChecklistFilterArgs>>;
  contract?: InputMaybe<StringExpression>;
  id?: InputMaybe<StringExpression>;
  isActive?: InputMaybe<BoolExpression>;
};

export type LoyaltyChecklistPaginated = {
  __typename?: 'LoyaltyChecklistPaginated';
  nodes: Array<Maybe<LoyaltyChecklist>>;
  totalCount: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createClient: Client;
  createLoyaltyChecklist: LoyaltyChecklist;
  createRole: Role;
  createUser: User;
  deleteClient: Client;
  deleteLoyaltyChecklist: LoyaltyChecklist;
  deleteRole: Role;
  deleteUser: User;
  deleteWalletFromUser: Wallet;
  updateClient: Client;
  updateLoyaltyChecklist: LoyaltyChecklist;
  updateRole: Role;
  updateUser: User;
};


export type MutationCreateClientArgs = {
  client: CreateClientInput;
};


export type MutationCreateLoyaltyChecklistArgs = {
  loyaltychecklist: CreateLoyaltyChecklistInput;
};


export type MutationCreateRoleArgs = {
  role: CreateRoleInput;
};


export type MutationCreateUserArgs = {
  user: CreateUserInput;
};


export type MutationDeleteClientArgs = {
  id: Scalars['String'];
};


export type MutationDeleteLoyaltyChecklistArgs = {
  id: Scalars['String'];
};


export type MutationDeleteRoleArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['String'];
};


export type MutationDeleteWalletFromUserArgs = {
  address: Scalars['String'];
};


export type MutationUpdateClientArgs = {
  id: Scalars['String'];
  updates: UpdateClientInput;
};


export type MutationUpdateLoyaltyChecklistArgs = {
  id: Scalars['String'];
  updates: UpdareLoyaltyChecklistInput;
};


export type MutationUpdateRoleArgs = {
  id: Scalars['Int'];
  updates: UpdateRoleInput;
};


export type MutationUpdateUserArgs = {
  id: Scalars['String'];
  updates: UpdateUserInput;
};

export type Query = {
  __typename?: 'Query';
  _service: _Service;
  findAllAudits: AuditPaginated;
  findAllClients: Array<Client>;
  findAllLoyaltyChecklists: LoyaltyChecklistPaginated;
  findAllRoles: Array<Role>;
  findAllUsers: UserPaginated;
  findClient: Client;
  findLoyaltyChecklist: LoyaltyChecklist;
  findUser: User;
};


export type QueryFindAllAuditsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AuditFilterArgs>;
};


export type QueryFindAllLoyaltyChecklistsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LoyaltyChecklistFilterArgs>;
};


export type QueryFindAllUsersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserFilterArgs>;
};


export type QueryFindClientArgs = {
  id: Scalars['String'];
};


export type QueryFindLoyaltyChecklistArgs = {
  id: Scalars['String'];
};


export type QueryFindUserArgs = {
  id: Scalars['String'];
};

export enum ResponseType {
  Code = 'CODE',
  IdToken = 'ID_TOKEN',
  IdTokenToken = 'ID_TOKEN_TOKEN',
  None = 'NONE'
}

export type Role = {
  __typename?: 'Role';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type StringExpression = {
  _eq?: InputMaybe<Scalars['String']>;
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  _like?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
};

export type UpdareLoyaltyChecklistInput = {
  contract?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  period?: InputMaybe<Scalars['Float']>;
  points?: InputMaybe<Scalars['Float']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<Scalars['String']>;
};

export type UpdateClientInput = {
  clientId: Scalars['String'];
  clientSecret?: InputMaybe<Scalars['String']>;
  grantTypes?: InputMaybe<Array<GrantType>>;
  name: Scalars['String'];
  redirectUris?: InputMaybe<Array<Scalars['String']>>;
  responseTypes?: InputMaybe<Array<ResponseType>>;
  scopes?: InputMaybe<Array<Scalars['String']>>;
  tokenEndpointAuthMethod: ClientAuthMethod;
};

export type UpdateRoleInput = {
  name: Scalars['String'];
};

export type UpdateUserInput = {
  discordAvator?: InputMaybe<Scalars['String']>;
  discordId?: InputMaybe<Scalars['String']>;
  discordUserName?: InputMaybe<Scalars['String']>;
  dob?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['String']>;
  kycVerified?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  roles: Array<Scalars['String']>;
  shopifyAccount?: InputMaybe<Scalars['String']>;
  shopifyVerified?: InputMaybe<Scalars['Boolean']>;
  username?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  avatar?: Maybe<Scalars['String']>;
  birthMonth?: Maybe<Scalars['String']>;
  birthYear?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  discordAvator: Scalars['String'];
  discordId?: Maybe<Scalars['String']>;
  discordToken: Scalars['String'];
  discordUserName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailVerified: Scalars['Boolean'];
  enabled: Scalars['Boolean'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  kycVerified: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  roles: Array<Scalars['String']>;
  shopifyAccount?: Maybe<Scalars['String']>;
  shopifyVerified: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  username?: Maybe<Scalars['String']>;
  wallets: Array<Wallet>;
};

export type UserFilterArgs = {
  _and?: InputMaybe<Array<UserFilterArgs>>;
  _or?: InputMaybe<Array<UserFilterArgs>>;
  email?: InputMaybe<StringExpression>;
  id?: InputMaybe<StringExpression>;
  username?: InputMaybe<StringExpression>;
  verified?: InputMaybe<BoolExpression>;
  wallets?: InputMaybe<WalletFilterArgs>;
};

export type UserPaginated = {
  __typename?: 'UserPaginated';
  nodes: Array<Maybe<User>>;
  totalCount: Scalars['Int'];
};

export type Wallet = {
  __typename?: 'Wallet';
  address: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  lastVerifyAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WalletFilterArgs = {
  _and?: InputMaybe<Array<WalletFilterArgs>>;
  _or?: InputMaybe<Array<WalletFilterArgs>>;
  address?: InputMaybe<StringExpression>;
};

export type _Service = {
  __typename?: '_Service';
  sdl?: Maybe<Scalars['String']>;
};

export type ClientFieldsFragment = { __typename?: 'Client', id: string, name: string, redirectUris: Array<string>, scopes: Array<string>, responseTypes: Array<ResponseType>, grantTypes: Array<GrantType>, tokenEndpointAuthMethod: ClientAuthMethod, clientSecret?: string | null, clientId: string, updatedAt: any, createdAt: any };

export type UpdateClientMutationVariables = Exact<{
  id: Scalars['String'];
  updates: UpdateClientInput;
}>;


export type UpdateClientMutation = { __typename?: 'Mutation', updateClient: { __typename?: 'Client', id: string } };

export type CreateClientMutationVariables = Exact<{
  client: CreateClientInput;
}>;


export type CreateClientMutation = { __typename?: 'Mutation', createClient: { __typename?: 'Client', id: string } };

export type DeleteClientMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteClientMutation = { __typename?: 'Mutation', deleteClient: { __typename?: 'Client', id: string } };

export type GetAllClientsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllClientsQuery = { __typename?: 'Query', findAllClients: Array<{ __typename?: 'Client', id: string, name: string, redirectUris: Array<string>, scopes: Array<string>, responseTypes: Array<ResponseType>, grantTypes: Array<GrantType>, tokenEndpointAuthMethod: ClientAuthMethod, clientSecret?: string | null, clientId: string, updatedAt: any, createdAt: any }> };

export type GetClientQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetClientQuery = { __typename?: 'Query', findClient: { __typename?: 'Client', id: string, name: string, redirectUris: Array<string>, scopes: Array<string>, responseTypes: Array<ResponseType>, grantTypes: Array<GrantType>, tokenEndpointAuthMethod: ClientAuthMethod, clientSecret?: string | null, clientId: string, updatedAt: any, createdAt: any } };

export type ChecklistFieldsFragment = { __typename?: 'LoyaltyChecklist', id: string, contract: string, isActive: boolean, startTime: any, createdAt: any, updatedAt: any, points: number, period: number, type: string };

export type UpdateChecklistMutationVariables = Exact<{
  id: Scalars['String'];
  updates: UpdareLoyaltyChecklistInput;
}>;


export type UpdateChecklistMutation = { __typename?: 'Mutation', updateLoyaltyChecklist: { __typename?: 'LoyaltyChecklist', id: string } };

export type CreateChecklistMutationVariables = Exact<{
  checklist: CreateLoyaltyChecklistInput;
}>;


export type CreateChecklistMutation = { __typename?: 'Mutation', createLoyaltyChecklist: { __typename?: 'LoyaltyChecklist', id: string } };

export type DeleteChecklistMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteChecklistMutation = { __typename?: 'Mutation', deleteLoyaltyChecklist: { __typename?: 'LoyaltyChecklist', id: string } };

export type GetAllChecklistsQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  where?: InputMaybe<LoyaltyChecklistFilterArgs>;
}>;


export type GetAllChecklistsQuery = { __typename?: 'Query', findAllLoyaltyChecklists: { __typename?: 'LoyaltyChecklistPaginated', totalCount: number, nodes: Array<{ __typename?: 'LoyaltyChecklist', id: string, contract: string, isActive: boolean, startTime: any, createdAt: any, updatedAt: any, points: number, period: number, type: string } | null> } };

export type GetChecklistQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetChecklistQuery = { __typename?: 'Query', findLoyaltyChecklist: { __typename?: 'LoyaltyChecklist', id: string, contract: string, isActive: boolean, startTime: any, createdAt: any, updatedAt: any, points: number, period: number, type: string } };

export type UserFieldsFragment = { __typename?: 'User', id: string, email: string, username?: string | null, kycVerified: boolean, roles: Array<string>, enabled: boolean, emailVerified: boolean, createdAt: any, updatedAt: any, birthYear?: string | null, birthMonth?: string | null, firstName?: string | null, lastName?: string | null, country?: string | null, region?: string | null, discordId?: string | null, discordUserName?: string | null, wallets: Array<{ __typename?: 'Wallet', address: string, name?: string | null, lastVerifyAt?: any | null, updatedAt?: any | null, createdAt?: any | null }> };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['String'];
  updates: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: string } };

export type CreateUserMutationVariables = Exact<{
  user: CreateUserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'User', id: string } };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: { __typename?: 'User', id: string } };

export type DeleteWalletFromUserMutationVariables = Exact<{
  address: Scalars['String'];
}>;


export type DeleteWalletFromUserMutation = { __typename?: 'Mutation', deleteWalletFromUser: { __typename?: 'Wallet', address: string } };

export type GetAllUsersQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  where?: InputMaybe<UserFilterArgs>;
}>;


export type GetAllUsersQuery = { __typename?: 'Query', findAllUsers: { __typename?: 'UserPaginated', totalCount: number, nodes: Array<{ __typename?: 'User', id: string, email: string, username?: string | null, kycVerified: boolean, roles: Array<string>, enabled: boolean, emailVerified: boolean, createdAt: any, updatedAt: any, birthYear?: string | null, birthMonth?: string | null, firstName?: string | null, lastName?: string | null, country?: string | null, region?: string | null, discordId?: string | null, discordUserName?: string | null, wallets: Array<{ __typename?: 'Wallet', address: string, name?: string | null, lastVerifyAt?: any | null, updatedAt?: any | null, createdAt?: any | null }> } | null> } };

export type GetUserQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetUserQuery = { __typename?: 'Query', findUser: { __typename?: 'User', id: string, email: string, username?: string | null, kycVerified: boolean, roles: Array<string>, enabled: boolean, emailVerified: boolean, createdAt: any, updatedAt: any, birthYear?: string | null, birthMonth?: string | null, firstName?: string | null, lastName?: string | null, country?: string | null, region?: string | null, discordId?: string | null, discordUserName?: string | null, wallets: Array<{ __typename?: 'Wallet', address: string, name?: string | null, lastVerifyAt?: any | null, updatedAt?: any | null, createdAt?: any | null }> } };

export type GetAllAuditsQueryVariables = Exact<{
  where?: InputMaybe<AuditFilterArgs>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type GetAllAuditsQuery = { __typename?: 'Query', findAllAudits: { __typename?: 'AuditPaginated', totalCount: number, nodes: Array<{ __typename?: 'Audit', id: string, ip?: string | null, country?: string | null, action: string, data?: any | null, createdAt: any } | null> } };
