/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import {
  Signer,
  utils,
  Contract,
  ContractFactory,
  BigNumberish,
  Overrides,
} from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type { PromiseOrValue } from "../../../common";
import type { Reward, RewardInterface } from "../../../contracts/mock/Reward";

const _abi = [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_counter",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "counter",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "orderId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "user",
        type: "address",
      },
    ],
    name: "onRewardStaking",
    type: "event",
  },
  {
    inputs: [],
    name: "counter",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "orderId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
    ],
    name: "rewardStaking",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

const _bytecode =
  "0x60a060405234801561001057600080fd5b5060405161027438038061027483398101604081905261002f91610037565b608052610053565b60006020828403121561004c5761004c600080fd5b5051919050565b608051610201610073600039600081816040015260b001526102016000f3fe608060405234801561001057600080fd5b50600436106100365760003560e01c806361bc221a1461003b578063a2a224dc14610078575b600080fd5b6100627f000000000000000000000000000000000000000000000000000000000000000081565b60405161006f91906100f4565b60405180910390f35b61008b610086366004610155565b61008d565b005b7f9f948157eedd342d6086e029ac59b0ef517493eef7f0f0a25aa787878ca2436e7f000000000000000000000000000000000000000000000000000000000000000083836040516100e09392919061019b565b60405180910390a15050565b805b82525050565b6020810161010282846100ec565b92915050565b805b811461011557600080fd5b50565b803561010281610108565b600073ffffffffffffffffffffffffffffffffffffffff8216610102565b61010a81610123565b803561010281610141565b6000806040838503121561016b5761016b600080fd5b60006101778585610118565b92505060206101888582860161014a565b9150509250929050565b6100ee81610123565b606081016101a982866100ec565b6101b660208301856100ec565b6101c36040830184610192565b94935050505056fea264697066735822122090e9a15a3569038c9eda31ed805e49f2444332f98b3b6caa587f0f61908b796064736f6c634300080f0033";

type RewardConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: RewardConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class Reward__factory extends ContractFactory {
  constructor(...args: RewardConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    _counter: PromiseOrValue<BigNumberish>,
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): Promise<Reward> {
    return super.deploy(_counter, overrides || {}) as Promise<Reward>;
  }
  override getDeployTransaction(
    _counter: PromiseOrValue<BigNumberish>,
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(_counter, overrides || {});
  }
  override attach(address: string): Reward {
    return super.attach(address) as Reward;
  }
  override connect(signer: Signer): Reward__factory {
    return super.connect(signer) as Reward__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): RewardInterface {
    return new utils.Interface(_abi) as RewardInterface;
  }
  static connect(address: string, signerOrProvider: Signer | Provider): Reward {
    return new Contract(address, _abi, signerOrProvider) as Reward;
  }
}
