import { useLazyQuery, useQuery } from "@apollo/client";
import { useCallback, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  GetAllChecklistsQuery,
  GetAllChecklistsQueryVariables,
} from "../../generated";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { GET_ALL_CHECKLISTS } from "./graphql/query";

export const ChecklistIndexPage = () => {
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);

  const { data, loading } = useQuery<
    GetAllChecklistsQuery,
    GetAllChecklistsQueryVariables
  >(GET_ALL_CHECKLISTS, { variables: { limit, offset: page * limit } });

  const navigate = useNavigate();

  return (
    <Card sx={{ display: "flex", height: "100%", flexDirection: "column" }}>
      <CardHeader
        title="Loyalty Checklists"
        action={
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              navigate("/checklists/new");
            }}
          >
            New
          </Button>
        }
      ></CardHeader>
      <CardContent sx={{ flex: 1 }}>
        <DataGridPro
          rows={data?.findAllLoyaltyChecklists?.nodes || []}
          rowCount={data?.findAllLoyaltyChecklists?.totalCount || 0}
          pagination
          paginationMode="server"
          loading={loading}
          page={page}
          pageSize={limit}
          onPageChange={(p) => {
            setPage(p);
          }}
          onPageSizeChange={(p) => {
            setLimit(p);
          }}
          columns={[
            { field: "id", headerName: "ID", flex: 1 },
            { field: "contract", headerName: "Contract", flex: 1 },
            { field: "startTime", headerName: "Start Time", flex: 1 },
            { field: "period", headerName: "Period", flex: 0.5 },
            { field: "points", headerName: "Points", flex: 0.5 },
            {
              field: "isActive",
              headerName: "Is Active",
              flex: 0.5,
              type: "boolean",
              renderCell: ({ value }) => {
                return !!value ? "Yes" : "No";
              },
            },

            { field: "updatedAt", headerName: "UpdatedAt", flex: 1 },
            {
              field: "actions",
              headerName: "Actions",
              flex: 0.5,
              renderCell: (row) => {
                return (
                  <ButtonGroup>
                    <Button
                      onClick={() => {
                        navigate(`/checklists/${row.row.id}/edit`);
                      }}
                    >
                      Edit
                    </Button>
                  </ButtonGroup>
                );
              },
            },
          ]}
        ></DataGridPro>
      </CardContent>
    </Card>
  );
};
