import { Box, Stack, Typography } from "@mui/material";
import { TokenWithMetadata } from "../types";

export const NFT = ({
  tokenWithMetadata,
  projectName,
}: {
  tokenWithMetadata: TokenWithMetadata;
  projectName: string;
}) => {
  return (
    <Stack
      sx={{
        width: "100%",
      }}
    >
      <Box
        component="img"
        src={tokenWithMetadata.imgUrl}
        alt="no image"
        sx={{ width: "100%", height: "100%" }}
      ></Box>

      <Stack
        direction="row"
        sx={{ width: "100%" }}
        justifyContent="space-between"
      >
        <Stack>
          <Typography>{projectName}</Typography>
          <Typography fontWeight="bold"># {tokenWithMetadata.id}</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
