import { useMutation } from "@apollo/client";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  ButtonGroup,
  Button,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import {
  DeleteWalletFromUserMutation,
  DeleteWalletFromUserMutationVariables,
  Wallet,
} from "../../../generated";
import { DELETE_WALLET } from "../graphql/mutation";

export const Wallets = ({
  wallets,
  loading,
}: {
  wallets: Wallet[];
  loading: boolean;
}) => {
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  const [deleteWallet, { data, error, loading: loadingDelete }] = useMutation<
    DeleteWalletFromUserMutation,
    DeleteWalletFromUserMutationVariables
  >(DELETE_WALLET);

  useEffect(() => {
    if (data?.deleteWalletFromUser?.address) {
      enqueueSnackbar("Wallet deleted", { variant: "success" });
      const index = wallets.findIndex(
        (wallet) => wallet.address === data.deleteWalletFromUser.address
      );
      if (index > -1) {
        wallets.splice(index, 1);
      }
    }
  }, [data]);

  return (
    <Card sx={{ display: "flex", height: "100%", flexDirection: "column" }}>
      <CardHeader title="Wallet"></CardHeader>
      <CardContent sx={{ flex: 1 }}>
        <DataGridPro
          autoHeight
          rows={wallets || []}
          rowCount={wallets.length || 0}
          pagination
          paginationMode="server"
          loading={loading}
          page={page}
          pageSize={limit}
          getRowId={(row) => row.address}
          onPageChange={(p) => {
            setPage(p);
          }}
          onPageSizeChange={(p) => {
            setLimit(p);
          }}
          columns={[
            { field: "name", headerName: "Name", flex: 0.2 },
            { field: "address", headerName: "Address", flex: 1 },
            { field: "updatedAt", headerName: "Updated At", flex: 0.5 },
            {
              field: "lastVerifyAt",
              headerName: "Last Verify At",
              flex: 0.5,
            },
            { field: "createdAt", headerName: "Created At", flex: 0.5 },
            {
              field: "actions",
              headerName: "Actions",
              flex: 0.5,
              renderCell: (row) => {
                return (
                  <ButtonGroup>
                    <Button
                      onClick={() => {
                        // navigate(`/checklists/${row.row.id}/edit`);
                        if (
                          window.confirm(
                            `Confirm to delete wallet ${row.row.address}?`
                          ) == true
                        ) {
                          deleteWallet({
                            variables: { address: row.row.address },
                          });
                        }
                      }}
                    >
                      Delete
                    </Button>
                  </ButtonGroup>
                );
              },
            },
          ]}
        ></DataGridPro>
      </CardContent>
    </Card>
  );
};
function enqueueSnackbar(arg0: string, arg1: { variant: string }) {
  throw new Error("Function not implemented.");
}
