import { gql } from "@apollo/client";

export const UPDATE_CHECKLIST = gql`
  mutation UpdateChecklist(
    $id: String!
    $updates: UpdareLoyaltyChecklistInput!
  ) {
    updateLoyaltyChecklist(id: $id, updates: $updates) {
      id
    }
  }
`;

export const CREATE_CHECKLIST = gql`
  mutation CreateChecklist($checklist: CreateLoyaltyChecklistInput!) {
    createLoyaltyChecklist(loyaltychecklist: $checklist) {
      id
    }
  }
`;

export const DELETE_CHECKLIST = gql`
  mutation DeleteChecklist($id: String!) {
    deleteLoyaltyChecklist(id: $id) {
      id
    }
  }
`;
