import _ from "lodash";
import { useState, useEffect } from "react";
import { Web3Provider } from "@ethersproject/providers";
import { ERC721, Moar } from "../contracts/moar/typechain-types";
import { PeopleInThePlaceTheyLove } from "../contracts/pptl/ethers-contracts";
import { Hanai } from "../contracts/hanai-p2/typechain-types";
import { ContractType, useContract } from "./useContract";
import { TokenDetails, TokenWithMetadata } from "../../pages/user/types";

export const useAllOwnedTokens = (
  library: Web3Provider,
  accounts: string[]
) => {
  const [tokens, setTokens] = useState<Array<TokenDetails>>([]);
  const [totalTokens, setTotalTokens] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const moarContractAddress = process.env.REACT_APP_ADDRESS_MOAR;
  const pptlContractAddress = process.env.REACT_APP_ADDRESS_PPTL;
  const hanaip2ContractAddress = process.env.REACT_APP_ADDRESS_HANAIP2;

  const moarContract = useContract(
    moarContractAddress,
    library,
    ContractType.MOAR
  );
  const pptlContract = useContract(
    pptlContractAddress,
    library,
    ContractType.PPTL
  );
  const hanaiContract = useContract(
    hanaip2ContractAddress,
    library,
    ContractType.HANAI_P2
  );

  const {
    tokens: moarTokens,
    loadTokens: loadMoarTokens,
    loading: loadingMoar,
  } = useOwnedTokens(moarContract, accounts, false);
  const {
    tokens: pptlTokens,
    loadTokens: loadPptlTokens,
    loading: loadingPptl,
  } = useOwnedTokens(pptlContract, accounts, false);
  const {
    tokens: hanaip2Tokens,
    loadTokens: loadHanaiTokens,
    loading: loadingHanai,
  } = useOwnedTokens(hanaiContract, accounts, false);

  const loadTokens = () => {
    setTokens([]);
    // console.log(moarTokens, pptlTokens, hanaip2Tokens);
    const moarTokensWithMetadata = loadTokensWithMetadata(
      moarTokens,
      moarContractAddress,
      ContractType.MOAR
    );
    const pptlTokensWithMetadata = loadTokensWithMetadata(
      pptlTokens,
      pptlContractAddress,
      ContractType.PPTL
    );
    const haniap2TokensWithMetadata = loadTokensWithMetadata(
      hanaip2Tokens,
      hanaip2ContractAddress,
      ContractType.HANAI_P2
    );
    // console.log(moarTokensWithMetadata, pptlTokensWithMetadata, haniap2TokensWithMetadata)

    const numTokens =
      moarTokens.length + pptlTokens.length + hanaip2Tokens.length;
    setTotalTokens(numTokens);
    setTokens([
      moarTokensWithMetadata,
      pptlTokensWithMetadata,
      haniap2TokensWithMetadata,
    ]);
  };

  const loadTokensWithMetadata = (
    tokens: string[],
    contractAddress: string,
    contractType: string
  ) => {
    const network =
      !process.env.NODE_ENV || process.env.NODE_ENV === "development"
        ? "goerli"
        : "mainnet";

    const tokensWithMetadata: Array<TokenWithMetadata> = tokens.map((token) => {
      return {
        id: token,
        imgUrl: `https://metadata.thefwenclub.com/nfts/image/${network}/${contractAddress}/${token.toString()}?width=500`,
      };
    });

    return {
      contractType,
      tokensWithMetadata,
      contractAddress,
    };
  };

  useEffect(() => {
    if (!accounts) {
      setLoading(false);
      console.log("load tokens", accounts);
      return setTokens([]);
    }
    setLoading(true);
    loadTokens();
    setLoading(false);
  }, [
    JSON.stringify(accounts),
    JSON.stringify(moarTokens),
    JSON.stringify(pptlTokens),
    JSON.stringify(hanaip2Tokens),
  ]);

  // const loading = loadingMoar || loadingPptl || loadingHanai;
  return { tokens, loadTokens, loading, totalTokens };
};

const useOwnedTokens = (
  contract: Moar | PeopleInThePlaceTheyLove | Hanai | ERC721,
  accounts: string[],
  minted?: boolean
) => {
  const [tokens, setTokens] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);

  const loadTokens = async () => {
    let tokenOwned = [];
    accounts.forEach(async (account) => {
      const transferInFilter = contract.filters.Transfer(undefined, account);
      const transferInEvents = await contract.queryFilter(transferInFilter, 0);
      const currentWalletTokens = _.uniq(
        _.compact(
          await Promise.all(
            _.map(transferInEvents, async (v) => {
              try {
                if (minted) {
                  return v.args.tokenId.toString();
                } else {
                  const owner = await contract.ownerOf(v.args.tokenId);
                  if (owner === account) {
                    return v.args.tokenId.toString();
                  }
                }
              } catch (e) {
                console.log(e);
              }
            })
          )
        )
      );
      tokenOwned = tokenOwned.concat(currentWalletTokens);
      setTokens(tokens.concat(tokenOwned));
    });

    return tokenOwned;
  };
  useEffect(() => {
    if (!contract || !accounts) {
      setLoading(false);
      console.log("load tokens", contract, accounts);
      return setTokens([]);
    }
    setLoading(true);
    loadTokens().finally(() => {
      setLoading(false);
    });
  }, [contract, JSON.stringify(accounts)]);
  return { tokens, loadTokens, loading };
};
