import { useMutation } from "@apollo/client";
import { Card, CardHeader, CardContent } from "@mui/material";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { buildErrorMessage } from "../../common/graphql/errors";
import {
  CreateChecklistMutation,
  CreateChecklistMutationVariables,
} from "../../generated";
import { ChecklistForm } from "./components/ChecklistForm";
import { CREATE_CHECKLIST } from "./graphql/mutation";

export const ChecklistCreatePage = () => {
  const [createChecklist, { data, error, loading }] = useMutation<
    CreateChecklistMutation,
    CreateChecklistMutationVariables
  >(CREATE_CHECKLIST);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (data?.createLoyaltyChecklist?.id) {
      enqueueSnackbar("Checklist created", { variant: "success" });
      navigate(`/checklists/${data.createLoyaltyChecklist.id}/edit`);
    }
  }, [data]);

  if (error) {
    enqueueSnackbar(buildErrorMessage(error), { variant: "error" });
  }

  return (
    <Card sx={{ display: "flex", height: "100%", flexDirection: "column" }}>
      <CardHeader title="New Checklist"></CardHeader>
      <CardContent sx={{ flex: 1 }}>
        <ChecklistForm
          type="create"
          initialValues={{
            isActive: true,
            startTime: dayjs(),
            contract: "",
            period: 30,
            points: 20,
          }}
          loading={loading}
          onSubmit={(values) => {
            createChecklist({ variables: { checklist: values } });
          }}
        ></ChecklistForm>
      </CardContent>
    </Card>
  );
};
